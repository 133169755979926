import React from "react"
import Layout from "../components/layout/Layout"
import ButtonLink from "../components/ui/ButtonLink"

export default ({ data }) => (
  <Layout wide>
    <h1>Demander l'accès Famille + Amis</h1>

    <p>
      <ButtonLink to="/">Retour à l'accueil</ButtonLink>
    </p>

    <iframe
      src="https://docs.google.com/forms/d/e/1FAIpQLSeheqHZjvem3JjRAqwPYVtf-5OAKrnAwOTmr2eE-_MipRksPg/viewform?embedded=true"
      width="100%"
      height="900"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      title="Formulaire de demande d'accès"
    >
      Chargement…
    </iframe>
  </Layout>
)
